<script setup lang="ts">
  import Error404 from '@/assets/error/404.svg'
  import { StatusMessageBlacklisted } from './types/apiError'
  import type { NuxtError } from '#app'
  import { useI18n } from 'vue-i18n'
  import { AbortNavigationMessage } from './types/error'
  import mixpanel from 'mixpanel-browser'

  const props = defineProps<{ error: NuxtError }>()

  const doRender = ref(props.error.statusMessage !== AbortNavigationMessage)

  const isBlacklisted = computed(() => {
    if (!doRender.value) return

    const { statusCode, statusMessage } = props.error

    return statusCode === 403 && statusMessage === StatusMessageBlacklisted
  })

  document.title = useI18n().t('Error.title')
  window.parent.postMessage({ scrollTop: true, isWeWiink: true }, '*')

  const showReloadLink = computed(() => {
    const { data } = props.error

    if (!data) return true

    const { reload } = data as { reload?: boolean }

    return isBlacklisted.value ?? reload
  })

  const onReload = () => {
    location.reload()
  }

  const postNewHeightMessage = () => {
    const { body, documentElement } = document
    const height = Math.max(body.scrollHeight, body.offsetHeight, documentElement.offsetHeight)
    window.parent.postMessage({ height, isWeWiink: true }, '*')
  }

  const runtimeConfig = useRuntimeConfig()

  const { env, mixpanelToken } = runtimeConfig.public

  mixpanel.init(mixpanelToken, {
    debug: env !== 'prod',
    ignore_dnt: true
  })

  const observer = ref<ResizeObserver | null>(null)
  onMounted(() => {
    if (!doRender.value) {
      window.parent.postMessage({ height: '0', isAccount: true, isWeWiink: true }, '*')
      return
    }

    observer.value = new ResizeObserver(postNewHeightMessage)
    observer.value.observe(document.body)

    const { company } = useStoreCompany()

    if (!company) return

    const eventName = isBlacklisted.value ? 'page-403-blacklisted-error' : 'page-404-error'
    mixpanel.track(eventName, {
      company_id: company.id,
      company_name: company.name,
      is_shopify: useEmbedded().isEmbedded.value,
      is_connected: useStoreAuth().isAuthenticated
    })
  })

  onUnmounted(() => {
    observer.value?.disconnect()
  })
</script>

<template>
  <div
    v-if="doRender"
    class="app-error"
  >
    <template v-if="isBlacklisted">
      <p class="app-error--message text-secondary-heading-2">
        {{ $t('Error.blacklistMessage1') }}
        <br />
        <br />
        {{ $t('Error.blacklistMessage2') }}
      </p>
    </template>

    <template v-else>
      <Error404 class="app-error--image" />
      <p class="app-error--message text-secondary-heading-2">
        {{ error?.statusMessage ?? $t('Error.generic') }}
      </p>
      <button
        v-if="showReloadLink"
        class="text-primary-heading-2 app-error--button"
        @click="onReload"
      >
        {{ $t('Error.link') }}
      </button>
    </template>
  </div>
</template>

<style scoped>
  .app-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 40px;
    margin-top: 100px;

    &--image,
    &--message {
      max-width: 50%;
    }

    &--message {
      text-align: center;
      word-wrap: break-word;
      min-height: 200px;
    }

    &--button {
      background-color: black;
      color: white;
      padding: 14.5px 24px;
      text-decoration: none;
      height: 50px;
    }
  }
</style>
