export default defineNuxtPlugin(() => {
  const api = $fetch.create({
    baseURL: useRuntimeConfig().public.baseUrl,

    onRequest(context) {
      const storeStore = useStoreStore(useNuxtApp().$pinia)
      if (storeStore.langCode && storeStore.isCurrentLangActive) {
        const endpoint = String(context.request)
        const localeParameter = `locale=${storeStore.langCode}`
        const paramChar = endpoint.includes('?') ? '&' : '?'
        context.request += `${paramChar}${localeParameter}`
      }

      const { options } = context
      const optionsHeaders = new Headers(options.headers)
      options.headers = optionsHeaders || {}

      // If third party cookies not allowed, use store for auth cookie storage
      const storeAuth = useStoreAuth(useNuxtApp().$pinia)
      let authCreator = storeAuth.authCreatorCookie

      if (!authCreator && storeAuth.authCreatorCookieFallback !== null) {
        authCreator = storeAuth.authCreatorCookieFallback
      }

      if (authCreator) {
        options.headers.set('authorization', `Token ${authCreator}`)
      } else {
        options.headers.set('authorization', 'null')
      }
    },

    onResponseError({ response }) {
      if (response.status === 401 || response.status === 403) {
        const store = useStoreAuth(useNuxtApp().$pinia)
        store.logout()
      }
      if (response.status >= 500) {
        showError({ statusCode: response.status })
      }
    }
  })
  return {
    provide: {
      api
    }
  }
})
