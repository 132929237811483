export class ApiErrorClass extends Error {
  override name: string
  statusCode: number
  response: {
    _data: {
      code?: string
      fields_data?: { [key: string]: string[] }
    }
  }

  constructor({ name, statusCode }: { name: string; statusCode: number }) {
    super()
    this.name = name
    this.statusCode = statusCode
    this.response = { _data: {} }
  }
}

export type GenericApiError = {
  detail: string
}

export enum ReferralErrorCode {
  CreatorDoesNotExists = 'referral_user_not_found',
  CreatorCanRefer = 'referral_user_cant_refer',
  TooManyReferralByIp = 'referral_too_many_referral_by_ip',
  HasEmailVerificationAndEmailNotSend = 'referral_missing_email',
  TooManyReferralByDay = 'referral_too_many_referral_by_day',
  AlreadyCustomerWithOrder = 'referral_email_has_order',
  CreatorHasSameEmail = 'referral_same_email'
}

export type ReferralError = {
  code: ReferralErrorCode
}

export const StatusMessageBlacklisted = 'blacklisted'
