import { init, browserTracingIntegration, thirdPartyErrorFilterIntegration } from '@sentry/vue'

export default defineNuxtPlugin({
  parallel: true,
  setup(nuxtApp) {
    const router = useRouter()

    const { public: config } = useRuntimeConfig()

    const { dsn } = config.sentry

    if (!dsn) {
      return
    }

    const ignoreRegexErrors = [
      /\[POST\].*creator\/companies-creators\/from-external-store.*<no response>/,
      /FetchError.\[GET\].*[401|403]/,
      /FetchError.\[GET\].*<no response>/,
      /AbortError/,
      /AbortNavigation/,
      /TypeError.+Importing a module script failed/,
      /TypeError.+Load failed/
    ]

    init({
      app: nuxtApp.vueApp,
      dsn,
      environment: config.env,
      integrations: [
        browserTracingIntegration({ router }),
        thirdPartyErrorFilterIntegration({
          filterKeys: ['loyoly-front-user'],
          behaviour: 'drop-error-if-exclusively-contains-third-party-frames'
        })
      ],

      beforeSend(event, hint) {
        const error = hint.originalException as { message?: string }

        if (error?.message) {
          for (const regex of ignoreRegexErrors) {
            if (error.message.match(regex)) {
              return null
            }
          }
        }
        return event
      }
    })
  }
})
